import React from 'react';

import { Home } from '../components/Home';
import { setCacheControl } from '../utils';

const Index = (props) => {
  return <Home {...props} />;
};

Index.getInitialProps = async (ctx) => {
  setCacheControl(ctx);
  return {
    hostname: ctx.req && ctx.req.headers['host'] ? ctx.req.headers['host'] : '',
  };
};

export default Index;
