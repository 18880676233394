import React, { useState } from 'react';
import Link from 'next/link';

import { Main } from './Main';

const getTitle = (hostname) => {
  return hostname === 'xn--billigatgresor-sib.se' ? 'Billiga Tågresor' : 'Tågresor i Europa';
};

const getDescription = (hostname) => {
  return hostname === 'xn--billigatgresor-sib.se'
    ? 'Här kommer du hitta Billiga Tågresor.'
    : 'Här hittar du Tågresor i Europa.';
};

const getLink = (hostname) => {
  return hostname === 'xn--billigatgresor-sib.se' ? (
    <h2 className="subtitle has-text-centered">Här kommer du hitta Billiga Tågresor.</h2>
  ) : (
      <h2 className="subtitle has-text-centered">
        <Link as="/search/" href="/search/">
          Här hittar du Tågresor i Europa.
      </Link>
      </h2>
    );
};

const getListId = () => {
  return window.location.hostname === 'xn--billigatgresor-sib.se' ? 'd4191beb94' : 'da5422807f';
};

export const Home = (props) => {
  const [state, setState] = useState({ errorClass: '', message: '', loading: false });
  const refEmail = React.createRef();

  const onFocus = () => {
    setState({ ...state, errorClass: '', message: '' });
  };

  const onClick = async () => {
    setState({ ...state, loading: true });
    const email = refEmail.current.value;
    const url =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3001/dev/subscribe'
        : 'https://train.api.services.srvc.io/subscribe';
    try {
      if (!email) {
        throw Error('email');
      }
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          email,
          list: getListId(),
        }),
      });
      if (res.status !== 200) {
        throw Error('404');
      }
      setState({ ...state, message: 'Du är nu anmäld', loading: false });
    } catch (error) {
      setState({
        ...state,
        errorClass: 'is-danger',
        loading: false,
        message: 'Något gick fel försök igen',
      });
    }
  };

  return (
    <Main
      title={getTitle(props.hostname)}
      description={getDescription(props.hostname)}
      hostname={props.hostname}
    >
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <h1 className="title is-spaced has-text-centered">{getTitle(props.hostname)}</h1>
                {getLink(props.hostname)}
                <p className="has-text-centered" style={{ marginBottom: '1.5rem' }}>
                  Registrera dig med din e-postadress för att få nyheter och uppdateringar.
                </p>
                <div className="field">
                  <label className="label" htmlFor="email">
                    Din E-postadress
                  </label>
                  <div className="control has-icons-left has-icons-right">
                    <input
                      id="email"
                      onFocus={onFocus}
                      ref={refEmail}
                      className={'input is-medium ' + state.errorClass}
                      type="email"
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-envelope" />
                    </span>
                    {state.errorClass && state.errorClass === 'is-danger' && (
                      <span className="icon is-small is-right">
                        <i className="fas fa-exclamation-triangle" />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-half">
                <div className="field">
                  <div className="control">
                    <button
                      onClick={onClick}
                      className={
                        state.loading
                          ? 'button is-fullwidth is-medium is-link is-loading'
                          : 'button is-fullwidth is-medium is-link'
                      }
                    >
                      Prenumerera
                    </button>
                  </div>
                </div>
                <p className={'help has-text-centered ' + state.errorClass}>{state.message}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  );
};
